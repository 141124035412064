.result {
    background-color: transparent;
    width: 100%;
    color: rgb(43, 84 , 126);
    word-break: break-all;
}

.result p {
    font-size: 40px;
    margin: 5px;

}
/* Dark mood */
.dark-mode .result {
    background-color: transparent;
    width: 100%;
    color: #e8b71a;
    word-break: break-all;
}

.dark-mode .result p {
    font-size: 40px;
    margin: 5px;

}