.header {
    margin-top: -3rem;
    text-align: end;
    margin-bottom: 4rem;
}
.header Button {
    margin-right: 10px;
    width: 4%;
    height: 10%;
    color: rgb(43, 84 , 126);
    font-weight: bolder;
    border: 1px solid  #1a2430 !important;
}

.header Button:hover {
    background: #1a2430 ;
    color:white;
}

@media screen and (max-width: 768px) {
    .header Button {
        width: 16%;
    }
}

/* dark mood */ 

.dark-mode .header {
    margin-top:-3rem;
    text-align: end;
    margin-bottom: 4rem;
}
.dark-mode .header Button {
    margin-right: 10px;
    width: 4%;
    height: 10%;
    color: white;
    font-weight: bolder;
    border: 1px solid #e8b71a !important;
}

.dark-mode .header Button:hover {
    background: #e8b71a ;
    color:white;
}

@media screen and (max-width: 768px) {
    .dark-mode .header Button {
        width: 16%;
    }
}