.gram .num {
    column-count: 2;
    margin-left: 2rem;
}
.gram h3 {
    color: #1a2430;
    line-height: 1.5;
    font-weight: bolder;
    font-family: 'Cairo', sans-serif;
}
.gram span {
    color: rgb(101, 158, 199);
    font-weight: bold;
}
.gram .two  {
    color: #1a2430;
    font-weight: bold;
}
.per>:nth-child(1){
    padding-top: 3rem;
    display: flex;
}
.per>:nth-child(1) > span {
    padding: .7rem;
    border-radius: 25px;
    background:#222c3b;
    color: #f8f8f8;
    font-size: 12px;
    height: 2rem;
    margin-top: 6px;
    line-height: 0rem;
    cursor: pointer;
}
.per>:nth-child(2) {
    margin-top: 1rem;
    column-count: 2;
    margin-left: 2rem;
}
.per>:nth-child(2) svg {
    margin-left: 9rem;
    margin-top: -1.6rem;
}
h5 {
    color: rgb(43, 84 , 126);
    font-weight: bold;
}
.per>:nth-child(2) > .dropdown {
    padding-left: 2rem;
    margin-top: -1.7rem;
    margin-bottom: 20px;
    position: absolute;
    padding-right: 13rem;
}
.dropdown > button {
    background: transparent;
    border: none;
    color: #536070;
    margin-top: -1rem;
} 
.btn.show {
    background-color: transparent !important;
}
.dropdown-menu.show {
    background: transparent;
    border: none;
    inset: -28px auto auto 8rem!important
}
.per>:nth-child(2) .dropdown-toggle::after {
    display: none;
}
.per>:nth-child(2) .dropdown > .btn:hover {
    background: transparent;
    color: #536070;
}
.per>:nth-child(2) > input {
    background: transparent;
    border: none;
    color: #1a2430;
    margin-bottom: 1rem;
    padding: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    font-weight: bold;
    width: 100%;
}
.per>:nth-child(2) > ::placeholder {
    color: #536070;
    font-weight: bold;
}
.per .child {
    padding-top: 0
}
.per .child > input {
    background: transparent;
    border: none;
    color: rgb(43, 84 , 126);
    margin-bottom: 1rem;
    padding: 0.7rem;
    border-radius: 5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
}
.per .child  > ::placeholder {
    color: #536070;
    font-weight: bold;
}
.gram[dir="auto"] {
    padding-right: 20px;
}
@media screen and (max-width: 768px) {
    .num {
        margin-left: 0!important 
    }
    .per>:nth-child(1) {
        padding-top: 2rem;
    }
    .per>:nth-child(2) {
        margin-left: 0;
    }
    .per>:nth-child(2) svg {
        margin-left: 4rem;
        margin-top: -1.5rem;
        margin-right: -9rem;
    }
    .per>:nth-child(2) > .dropdown {
        margin-top: -1.6rem;
        margin-bottom: 20px;
        position: absolute;
        padding-right: 13rem;
        padding-left: 1rem;
    }
    .dropdown-menu.show {
        inset: -35px auto auto 5.5rem!important;
    }
}