.calculator-body {
  max-width: 400px;
  margin-bottom: 3rem;
  margin-left: 22rem;
  margin-top: 5rem;
}

.button {
  display: block;
  background-color: transparent;
}

button {
  width: 20%;
  height: 60px;
  font-size: 16px;
  font-weight: bold;  
  background-color: transparent;
  color: rgb(43, 84 , 126);
  border: none;
}

.button .diff {
  background-color: #1a2430;
  border-radius: 50%;
  color: white
}

button:nth-child(3), 
button:nth-child(6), 
button:nth-child(9),
button:nth-child(13) {
  padding-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .calculator-body {
    margin-left: -2rem !important;
    margin-top: 0;
  }
}

/* dar mood */ 


.dark-mode .calculator-body {
  max-width: 400px;
  margin-bottom: 3rem;
  margin-left: 22rem;
  margin-top: 5rem;
}

.dark-mode .button {
  display: block;
  background-color: transparent;
}

.dark-mode button {
  width: 20%;
  height: 60px;
  font-size: 16px;
  font-weight: bold;  
  background-color: transparent;
  color: white;
  border: none;
}

.dark-mode .button .diff {
  background-color: #1a2430;
  border-radius: 50%;
  color: #e8b71a
}

.dark-mode button:nth-child(3), 
.dark-mode button:nth-child(6), 
.dark-mode button:nth-child(9),
.dark-mode button:nth-child(13) {
  padding-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .dark-mode .calculator-body {
    margin-left: -2rem !important;
    margin-top: 0;
  }
}