@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding:0 ;
}

body {
    background-color: white;
    font-family: 'Outfit','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    align-items: center;
}

.app {
    padding-left: 2rem
}

h1 {
    color: rgb(43, 84 , 126);
    font-weight: bold;
}

.toggle{
    position: relative;
    top:1rem;
    display: inline-block;
    width: 49px;
    height: 21px
}

  /* Hide default HTML checkbox */
.toggle input {
    opacity: 1;
    width: 0;
    height: 0;
}

  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    -webkit-transition: .4s;
    transition: .4s;  

}
.slider:before {
    position: absolute; 
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;  
}

input:checked + .slider {
    background-color: white;  
}

input:checked + .slider::before {
    background-color: black;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;  
}

/* dark mood */ 
.dark-mode{
    background: black;
    color: #fff;
}

.dark-mode h1 {
    color: #e8b71a;
}

.dark {
    transition: all var(--cubic);
    display: flex;
    width: 4rem;
    height: 4rem;
    justify-content: center;
    align-items: center;
    transform: rotate(0);
} 

.dark svg:hover {
    transform: rotate(-25deg);
    cursor: pointer;
}


.dark svg::before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}

.dark svg::after, .dark svg::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 2px;
    border-right: 4px solid;
    border-left: 4px solid;
    left: -6px;
    top: 5px; 
}